<template>
  <div class="broadcast">
    hey
  </div>
</template>

<script>

import CreekShows from '@/lib/creek/views/BroadcastPage';

export default {
  name: 'Broadcast',
  data(){
    return {

    }
  },
  props: {},
  methods: {

  },
  created(){

  }
}
</script>